import React from 'react';
import { useClipboard } from 'use-clipboard-copy';
import CopyVector from "../images/Copy.svg"

const LinkCopy = () => {
    const clipboard = useClipboard({ 
      copiedTimeout: 1250,
      onSuccess() {
        console.log('Text was copied successfully!')
      },
      onError() {
        console.log('Failed to copy text!')
      }
    });
    
    return (
      <div className="copyLink">
        <form>  
          <input ref={clipboard.target} value="getsoundbite.app" readOnly />
          <button type="button" onClick={clipboard.copy}>
            {clipboard.copied ? '👍' : <img src={CopyVector} alt="Vector for Copy Button"></img>}
          </button>
        </form>
      </div>
    )
}


export default LinkCopy;