import React from "react"

// Components
import SEO from "../components/seo"
import { Link } from "gatsby"
import LinkCopy from "../components/linkcopier"

// Images
import slack from "../images/Slack.svg"
import airpodLeft from "../images/Airpods (Left).svg"
import airpodRight from "../images/Airpods (Right).svg"
import facebookWhite from "../images/Facebook White.svg"
import twitterWhite from "../images/Twitter White.svg"


const Thanks = () => {

  return (
    <>    
    <div className="logo__compact">
      <Link to="/">
       {/* <img src={logo} alt="Soundbite Logo"></img> */}
      
       <svg viewBox="0 0 399 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id = "wordmark"><path d="M128.19 43.155C128.19 35.355 119.675 33.99 112.915 33.08C109.145 32.495 106.22 31.845 106.22 29.635C106.285 27.425 108.69 25.865 111.68 25.865C115.58 25.865 118.505 27.75 118.505 30.48H127.085C127.085 23.785 120.65 18.975 111.745 18.975C103.62 18.975 97.445 23.655 97.445 30.155C97.445 38.28 103.62 39.45 111.03 40.36C115.06 40.75 118.83 41.53 118.83 44.325C118.83 46.535 116.23 48.225 112.655 48.225C108.43 48.225 105.375 45.885 105.375 42.57H97.12C97.055 49.85 103.555 55.115 112.525 55.115C121.82 55.115 128.19 50.11 128.19 43.155Z" fill="#102640"/>
<path d="M148.505 19.105C137.585 19.105 130.045 26.385 130.045 37.045C130.045 47.64 137.585 54.985 148.505 54.985C159.49 54.985 167.03 47.64 167.03 37.045C167.03 26.385 159.49 19.105 148.505 19.105ZM148.505 46.405C143.435 46.405 139.86 42.505 139.86 37.045C139.86 31.585 143.435 27.685 148.505 27.685C153.64 27.685 157.215 31.585 157.215 37.045C157.215 42.505 153.64 46.405 148.505 46.405Z" fill="#102640"/>
<path d="M202.424 53.685V20.405H192.674V39.125C192.674 43.35 189.554 46.405 185.719 46.405C181.884 46.405 179.544 43.545 179.544 39.515V20.405H169.794V41.205C169.794 49.2 174.864 54.985 182.339 54.985C186.694 54.985 190.594 52.775 192.934 49.135V53.685H202.424Z" fill="#102640"/>
<path d="M207.378 20.405V53.685H217.128V34.965C217.128 30.74 220.248 27.685 224.083 27.685C227.918 27.685 230.258 30.545 230.258 34.575V53.685H240.008V32.885C240.008 24.89 234.938 19.105 227.463 19.105C223.108 19.105 219.208 21.315 216.868 24.89V20.405H207.378Z" fill="#102640"/>
<path d="M261.278 27.88C266.348 27.88 269.988 31.78 269.988 37.045C269.988 42.31 266.348 46.21 261.278 46.21C256.273 46.21 252.568 42.375 252.568 37.045C252.568 31.78 256.208 27.88 261.278 27.88ZM242.818 37.045C242.818 47.575 249.383 54.985 258.613 54.985C263.293 54.985 267.128 52.97 269.728 49.655V53.685H279.348V5.455H269.598V24.435C266.998 21.055 263.163 19.105 258.613 19.105C249.383 19.105 242.818 26.515 242.818 37.045Z" fill="#102640"/>
<path d="M302.364 27.88C307.434 27.88 311.074 31.78 311.074 37.045C311.074 42.375 307.369 46.21 302.364 46.21C297.294 46.21 293.654 42.31 293.654 37.045C293.654 31.78 297.294 27.88 302.364 27.88ZM320.824 37.045C320.824 26.515 314.324 19.105 305.029 19.105C300.479 19.105 296.709 21.055 294.044 24.435V5.455H284.294V53.685H293.914V49.655C296.579 52.97 300.414 54.985 305.029 54.985C314.259 54.985 320.824 47.575 320.824 37.045Z" fill="#102640"/>
<path d="M325.056 20.405V53.685H334.806V20.405H325.056ZM329.931 15.985C333.246 15.985 335.586 13.71 335.586 10.46C335.586 7.275 333.246 5 329.931 5C326.616 5 324.276 7.275 324.276 10.46C324.276 13.71 326.616 15.985 329.931 15.985Z" fill="#102640"/>
<path d="M343.052 42.895C343.052 49.98 347.342 54.985 353.972 54.985C356.052 54.985 358.652 54.595 360.667 53.945V46.08C359.562 46.405 358.132 46.6 357.222 46.6C354.492 46.6 352.802 44.715 352.802 41.92V27.815H360.342V20.405H352.802V12.02H344.612L343.507 20.405H338.372V27.815H343.052V42.895Z" fill="#102640"/>
<path d="M372.636 33.925C373.481 29.505 376.471 26.58 380.761 26.58C385.181 26.58 388.431 29.7 389.016 33.925H372.636ZM362.626 36.785C362.626 47.51 370.166 54.985 380.891 54.985C389.016 54.985 396.036 50.24 397.661 43.74L388.886 42.505C387.846 45.43 384.792 47.51 381.151 47.51C376.731 47.51 373.546 44.455 372.701 39.775L398.766 39.84C398.896 39.125 398.896 37.76 398.896 36.915C398.896 26.385 391.616 19.105 380.956 19.105C370.231 19.105 362.626 26.385 362.626 36.785Z" fill="#102640"/>
</g>

<g id="brandmark">
<path d="M20.1816 1.61339L62.146 23.9832C68.8352 27.549 71.3672 35.8623 67.8014 42.5514C66.5116 44.971 64.5267 46.9488 62.1026 48.2299L20.1381 70.4069C13.4362 73.9487 5.13208 71.3869 1.59032 64.685C0.545923 62.7087 0 60.5073 0 58.2721L0 13.7252C0 6.14498 6.14498 0 13.7252 0C15.9771 0 18.1944 0.554083 20.1816 1.61339Z" fill="#56FFDA"/>
<path fillRule="evenodd" clipRule="evenodd" d="M62.146 23.9832L20.1816 1.61339C18.1944 0.554083 15.9771 0 13.7252 0C6.14498 0 0 6.14498 0 13.7252V58.2721C0 60.5073 0.545923 62.7087 1.59032 64.685C5.13208 71.3869 13.4362 73.9487 20.1381 70.4069L62.1026 48.2299C64.5267 46.9488 66.5116 44.971 67.8014 42.5514C71.3672 35.8623 68.8352 27.549 62.146 23.9832ZM44.6166 37.1225C45.6267 35.944 45.4902 34.1698 44.3117 33.1597C43.1333 32.1496 41.3591 32.2861 40.3489 33.4645C36.6837 37.7407 33.266 39.5553 30.2792 39.6748C27.3599 39.7915 24.0318 38.3155 20.4341 34.1181C19.424 32.9397 17.6498 32.8032 16.4713 33.8133C15.2928 34.8234 15.1563 36.5976 16.1665 37.7761C20.4117 42.7288 25.2534 45.5011 30.5038 45.2911C35.6868 45.0837 40.4389 41.9965 44.6166 37.1225Z" fill="#102640"/>
</g>
</svg>



       
      </Link>
    </div>
      <SEO title="Thank you! 😍" />
      <main>
        <section className="thanks card">
          <h1><svg className ="icon"width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.5 24C46.5 36.45 36.45 46.5 24 46.5C11.55 46.5 1.5 36.45 1.5 24C1.5 11.55 11.55 1.5 24 1.5C36.45 1.5 46.5 11.55 46.5 24Z" fill="#FFDD67"/>
<path d="M46.35 9.90002C45.975 7.87502 44.85 6.22502 42.975 5.70002C40.95 5.17502 39.1501 5.92502 37.4251 7.72502C36.4501 5.02502 34.9501 3.00002 32.5501 1.95002C30.1501 0.900021 27.75 1.65002 26.25 3.52502C24.675 5.47502 24.075 8.55002 25.725 12.525C27.3 16.275 34.275 23.775 34.5 24C34.8 23.85 42.6 18.975 44.475 16.575C46.35 14.25 46.725 11.925 46.35 9.90002Z" fill="#FF3E6C"/>
<path d="M21.75 3.52502C20.25 1.65002 17.85 0.900021 15.45 1.95002C13.05 3.00002 11.55 5.02502 10.575 7.72502C8.77502 6.00002 6.97502 5.17502 4.95002 5.77502C3.15002 6.30002 1.95002 7.95002 1.57502 9.97502C1.20002 11.925 1.65002 14.325 3.45002 16.65C5.40002 18.975 13.2 23.85 13.5 24C13.725 23.775 20.7 16.275 22.275 12.525C23.925 8.55002 23.325 5.55002 21.75 3.52502Z" fill="#FF3E6C"/>
<path d="M36.75 28.575C36.75 27.975 36.375 27.225 35.4 27C32.775 26.475 28.95 26.025 24 26.025C19.05 26.025 15.225 26.55 12.6 27C11.55 27.225 11.25 27.975 11.25 28.575C11.25 34.05 15.45 39.525 24 39.525C32.55 39.45 36.75 33.975 36.75 28.575Z" fill="#664E27"/>
<path d="M33.525 28.725C31.875 28.425 28.425 27.975 24 27.975C19.575 27.975 16.125 28.425 14.475 28.725C13.5 28.875 13.425 29.25 13.5 29.85C13.575 30.15 13.575 30.6 13.725 31.05C13.8 31.5 13.95 31.725 14.7 31.65C16.125 31.5 31.95 31.5 33.375 31.65C34.125 31.725 34.2 31.5 34.35 31.05C34.425 30.6 34.5 30.225 34.575 29.85C34.575 29.25 34.5 28.875 33.525 28.725Z" fill="white"/>
</svg>
Thanks!<svg className ="icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M46.5 24C46.5 36.45 36.45 46.5 24 46.5C11.55 46.5 1.5 36.45 1.5 24C1.5 11.55 11.55 1.5 24 1.5C36.45 1.5 46.5 11.55 46.5 24Z" fill="#FFDD67"/>
<path d="M46.35 9.90002C45.975 7.87502 44.85 6.22502 42.975 5.70002C40.95 5.17502 39.1501 5.92502 37.4251 7.72502C36.4501 5.02502 34.9501 3.00002 32.5501 1.95002C30.1501 0.900021 27.75 1.65002 26.25 3.52502C24.675 5.47502 24.075 8.55002 25.725 12.525C27.3 16.275 34.275 23.775 34.5 24C34.8 23.85 42.6 18.975 44.475 16.575C46.35 14.25 46.725 11.925 46.35 9.90002Z" fill="#FF3E6C"/>
<path d="M21.75 3.52502C20.25 1.65002 17.85 0.900021 15.45 1.95002C13.05 3.00002 11.55 5.02502 10.575 7.72502C8.77502 6.00002 6.97502 5.17502 4.95002 5.77502C3.15002 6.30002 1.95002 7.95002 1.57502 9.97502C1.20002 11.925 1.65002 14.325 3.45002 16.65C5.40002 18.975 13.2 23.85 13.5 24C13.725 23.775 20.7 16.275 22.275 12.525C23.925 8.55002 23.325 5.55002 21.75 3.52502Z" fill="#FF3E6C"/>
<path d="M36.75 28.575C36.75 27.975 36.375 27.225 35.4 27C32.775 26.475 28.95 26.025 24 26.025C19.05 26.025 15.225 26.55 12.6 27C11.55 27.225 11.25 27.975 11.25 28.575C11.25 34.05 15.45 39.525 24 39.525C32.55 39.45 36.75 33.975 36.75 28.575Z" fill="#664E27"/>
<path d="M33.525 28.725C31.875 28.425 28.425 27.975 24 27.975C19.575 27.975 16.125 28.425 14.475 28.725C13.5 28.875 13.425 29.25 13.5 29.85C13.575 30.15 13.575 30.6 13.725 31.05C13.8 31.5 13.95 31.725 14.7 31.65C16.125 31.5 31.95 31.5 33.375 31.65C34.125 31.725 34.2 31.5 34.35 31.05C34.425 30.6 34.5 30.225 34.575 29.85C34.575 29.25 34.5 28.875 33.525 28.725Z" fill="white"/>
</svg>
</h1>
          <p id="content">We’ve added you to the waitlist for the beta, which you’ll be receiving an email about shortly. We really appreciate your interest in Soundbite, you’ll be hearing more from us in the coming weeks.</p>
          
          <div className="callout">
          <img src={slack} alt="Slack Logo for the Soundbite Community"></img>
          <p>Until then, why <a href="https://teamsoundbite.slack.com/join/shared_invite/enQtNzYyNzAxNTc0NzM3LWMyNWQxMjQ2ZDliNWQzNDI1MzU1NjIxMWQyODU5ZTE3NDZhOGMzNGQ3NDAzOTViZTY2ZDg3NTgwMGM3Nzg3NjE">not join our Slack?</a> Tell us how you found us, and what you’d like to see. </p>
          </div>

          <p id="content">How about letting others know about Soundbite?</p>
          
          <div className="socialButtons">

            <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.getsoundbite.app" target="_blank" rel="noopener noreferrer">
              <button className="facebook">Share on Facebook<img src={facebookWhite} alt=""/></button>
            </a>
        
          <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.getsoundbite.app&via=getsoundbiteapp&text=Soundbite%20is%20a%20podcast%20app%20to%20help%20you%20find%20hidden%20gems%20through%20bite-sized%20listens%20&hashtags=podcasts%2C%20app%2C%20soundbite" target="_blank" rel="noopener noreferrer">
            <button className="twitter">Share on Twitter<img src={twitterWhite} alt=""/></button>
          </a>

          </div>

          <p id="content" className="copyLink__prompt">Not on any of those? No problem. Use the ol’ copy and paste technique.</p>
          
          <LinkCopy></LinkCopy>
        
        <div className="airpods">
          <img src={airpodLeft} className="airpod--left" alt=""/>
          <img src={airpodRight} className="airpod--right" alt=""/>
        </div>
        </section>
      </main>

      <footer className="footnote">
      <p id="copyright">Soundbite is a Darn Good product © {new Date().getFullYear()}</p>
      </footer>
      
    </>
  )

}
  

export default Thanks


